<template>
  <div class="live gray-bcg-color">
    <c-banner :bannerData="bannerData"
              :src="src"></c-banner>
    <c-menu :menuData="menuData"></c-menu>
    <a name="advantage"
       class="anchor-replace"></a>
    <div class="v-advantage">
      <c-title :titleArr="advantageTitle"></c-title>
      <function-desc :imgData="imgData"
                     :functionData="advantageData"
                     :isForm="true"></function-desc>
    </div>
    <a name="function"
       class="form-yanshi"></a>
    <div class="function">
      <c-title :titleArr="functionTitle"></c-title>
      <div class="function-content  c-width">
        <div class="content_l">
          <div class="title">
            <h3>题库快速导入</h3>
            <div class="subtitle">您创建的问题都会录入题库，以备下次使用</div>
            <div class="line"></div>
          </div>
          <ul class="list">
            <li v-for="(item, index) in functionData"
                :key="index">
              <div class="img">
                <img :src="item.src"
                     alt=""
                     width="28" />
              </div>
              <div class="text-wrap">
                <div class="text-title">{{ item.title }}</div>
                <div class="text-content">
                  {{ item.text
                  }}<span v-if="item.text1"
                        class="main">{{ item.text1 }}</span>
                </div>
              </div>
            </li>
          </ul>
        </div>
        <div class="img-wrap">
          <el-image :src="require('../../../assets/images/form/function.png')"
                    alt=""
                    lazy></el-image>
        </div>
      </div>
      <div class="function-content form-image c-width">
        <div class="img-wrap">
          <el-image :src="require('../../../assets/images/form/form-image.png')"
                    alt=""
                    lazy></el-image>
        </div>
        <div class="content_l">
          <div class="title">
            <h3>图片自定义添加</h3>
            <div class="subtitle">
              制作带有自己LOGO的问卷，用户触达中融<br />入品牌标识和风格
            </div>
            <div class="line"></div>
          </div>
          <ul class="list">
            <li v-for="(item, index) in functionData"
                :key="index">
              <div class="img">
                <img :src="item.src"
                     alt=""
                     width="28" />
              </div>
              <div class="text-wrap">
                <div class="text-title">{{ item.title }}</div>
                <div class="text-content">
                  {{ item.text
                  }}<span v-if="item.text1"
                        class="main">{{ item.text1 }}</span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <a name="scene"
       class="anchor-replace"></a>
    <div class="scene c-width">
      <c-title :titleArr="sceneTitle"></c-title>
      <div class="sub-title">
        表单服务具有很多业务场景，您可以根据自己的需求来具体应用，常用于问卷调研、<br />签到、投票等
      </div>
      <div class="scene-content">
        <div class="img">
          <el-image :src="require('../../../assets/images/form/questionnaire.png')"
                    alt=""
                    lazy></el-image>
        </div>
        <div class="img">
          <el-image :src="require('../../../assets/images/form/qiandao.png')"
                    alt=""
                    lazy></el-image>
        </div>
        <div class="img">
          <el-image :src="require('../../../assets/images/form/toupian.png')"
                    alt=""
                    lazy></el-image>
        </div>
      </div>
    </div>
    <div class="more-scene c-width">
      <h3 class="title">更多业务场景展示</h3>
      <ul class="more-scene-content">
        <li v-for="(item, index) in moreSceneData"
            :key="index">
          <img :src="item.src"
               alt=""
               class="scene-img"
               lazy />
          <div class="text-title">{{ item.title }}</div>
          <div class="text">{{ item.text1 }}</div>
          <div class="text">{{ item.text2 }}</div>
        </li>
      </ul>
    </div>
    <a name="price"
       class="form-price"></a>
    <div class="price c-width">
      <c-title :titleArr="priceTitle"></c-title>
      <div class="sub-title">根据收集回的表单条数进行计费统计</div>
      <c-price :priceData="priceData"
               :isForm="true"></c-price>
    </div>
    <a name="doc"
       class="anchor-replace"></a>
    <div class="document">
      <c-title class="doc"
               :titleArr="documentTitle"
               :isDocTitle="true">
      </c-title>
      <div class="doc-content c-width">
        <a :href="`${docSrc}docs/show/272.html`"
           target="_blank">产品文档</a>
        <a :href="`${docSrc}docs/show/278.html`"
           target="_blank">快速开始</a>
        <a :href="`${docSrc}docs/show/281.html`"
           target="_blank">API</a>
        <a :href="`${docSrc}docs/show/329.html`"
           target="_blank">SDK</a>
        <a :href="`${docSrc}docs/show/332.html`"
           target="_blank">常见问题</a>
      </div>
    </div>
    <a name="recommand"
       class="anchor-replace"></a>
    <div>
      <c-title :titleArr="recommendTitle"></c-title>
      <c-recommend curService="form"></c-recommend>
    </div>
  </div>
</template>

<script>
import { cBanner, cMenu, cRecommend, cPrice } from '../components'
import { cTitle } from '@/components'
import functionDesc from '../document/components/function-desc.vue'
export default {
  components: {
    cBanner,
    cMenu,
    cTitle,
    functionDesc,
    cRecommend,
    cPrice
  },
  data () {
    return {
      docSrc: window.urlConfig.docUrl,
      priceData: [
        {
          bcg: '#00EB82',
          title: '100元／月',
          text: '含100000条',
          adviceUrl: '',
          detailUrl: `${window.urlConfig.docUrl}docs/show/274.html`
        },
        {
          bcg: '#65A4FE',
          title: '200元／月',
          text: '含250000条',
          adviceUrl: '',
          detailUrl: `${window.urlConfig.docUrl}docs/show/274.html`
        },
        {
          bcg: '#EEAF56',
          title: '300元／月',
          text: '含500000条',
          adviceUrl: '',
          detailUrl: `${window.urlConfig.docUrl}docs/show/274.html`
        },
        {
          bcg: '#605DE6',
          title: '500元／月',
          text: '含1000000条',
          adviceUrl: '',
          detailUrl: `${window.urlConfig.docUrl}docs/show/274.html`
        }
      ],
      priceTitle: ['产品价格'],
      documentTitle: ['产品文档'],
      recommendTitle: ['相关推荐'],
      moreSceneData: [
        {
          src: require('../../../assets/images/form/question-search.png'),
          title: '问卷调查',
          text1: '市场调查',
          text2: '反馈调查'
        },
        {
          src: require('../../../assets/images/form/toupiao-xuanju.png'),
          title: '投票选举',
          text1: '人气投票',
          text2: '比赛评选'
        },
        {
          src: require('../../../assets/images/form/exam.png'),
          title: '教学测评',
          text1: '学生测评',
          text2: '环境测评'
        },
        {
          src: require('../../../assets/images/form/regist.png'),
          title: '登记表',
          text1: '人员登记',
          text2: '活动登记'
        },
        {
          src: require('../../../assets/images/form/manyidu.png'),
          title: '满意度调查',
          text1: '服务评价',
          text2: '教学质量'
        }
      ],
      advantageData: [
        {
          className: 'v-frame1',
          src: require('../../../assets/images/doc/advantage1@2x.png'),
          title: '快速对接',
          text: '基于表单服务sdk，搭建客户业务场景的全套数据服务'
        },
        {
          className: 'v-frame2',
          src: require('../../../assets/images/doc/advantage2@2x.png'),
          title: '数据分析',
          text:
            '帮助您及时了解用户反馈，获取表单收集数、热门表单、地域、终端分布等数据'
        },
        {
          className: 'v-frame3',
          src: require('../../../assets/images/form/advantage-image.png'),
          title: '精美配图',
          text: '设计问卷时，可以在题目中插入图片或标识'
        },
        {
          className: 'v-frame4',
          src: require('../../../assets/images/form/edit-form.png'),
          title: '编辑表单',
          text: '快速编辑，可在移动端直接编辑和回复表单'
        },
        {
          className: 'v-frame5',
          src: require('../../../assets/images/doc/advantage3@2x.png'),
          title: '表单收集',
          text: '不限表单收集数量、支持大量用户同时使用表单服务，快速收集答案'
        }
      ],
      sceneTitle: ['业务场景'],
      functionData: [
        {
          src: require('../../../assets/images/form/commonUsed.png'),
          title: '常用',
          text: '姓名、性别、时间/日期、城市'
        },
        {
          src: require('../../../assets/images/form/allUsed.png'),
          title: '通用',
          text: '单选、多选、填空、单选表格、多选表格、',
          text1: '从题库中选择'
        },
        {
          src: require('../../../assets/images/form/remark.png'),
          title: '备注',
          text: '分页、分割线、图文备注'
        }
      ],
      functionTitle: ['功能演示'],
      advantageTitle: ['专业、易上手的表单服务'],
      imgData: [
        {
          className: 'v-img1',
          src: require('../../../assets/images/form/advantage-group.png')
        }
      ],
      bannerData: {
        title: '表单',
        textData: [
          '多维度数据统计分析',
          '多端SDK快速接入',
          '便捷、高效的编辑表单，满足各类应用场景'
        ],
        href2: `${window.urlConfig.docUrl}docs/show/329.html`
      },
      src: require('../../../assets/images/form/form-banner.png'),
      menuData: [
        {
          src: require('../../../assets/images/subnav1@2x.png'),
          id: '#advantage',
          text: '功能介绍'
        },
        {
          src: require('../../../assets/images/subnav2@2x.png'),
          id: '#function',
          text: '功能演示'
        },
        {
          src: require('../../../assets/images/subnav3@2x.png'),
          id: '#scene',
          text: '业务场景'
        },
        {
          src: require('../../../assets/images/subnav4@2x.png'),
          id: '#price',
          text: '产品价格'
        },
        {
          src: require('../../../assets/images/subnav5@2x.png'),
          id: '#doc',
          text: '产品文档'
        },
        {
          src: require('../../../assets/images/subnav6@2x.png'),
          id: '#recommand',
          text: '相关推荐'
        }
      ]
    }
  }
}
</script>

<style lang="less" scoped>
@import '../../../assets/less/varible.less';
.live {
  .doc {
    padding-top: 44px;
  }
  .function {
    padding-top: 150px;
  }
  .form-yanshi {
    position: relative;
    top: 100px;
  }
  .form-price {
    position: relative;
    top: 15px;
  }
  .function-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 64px;
    .content_l {
      .title {
        h3 {
          font-size: 30px;
          line-height: 42px;
          color: #333;
          font-weight: 500;
          white-space: nowrap;
        }
        .subtitle {
          line-height: 28px;
          margin: 20px 0 30px;
          font-size: 20px;
          color: #666;
          white-space: nowrap;
        }
        .line {
          width: 100%;
          height: 1px;
          background: #e5e8eb;
        }
      }
      .list {
        li {
          display: flex;
          margin-bottom: 60px;
          align-items: center;
          &:first-child {
            margin-top: 30px;
          }
          .text-wrap {
            margin-left: 30px;
            white-space: nowrap;
            .text-title {
              font-size: 18px;
              color: #555;
              font-weight: 500;
            }
            .text-content {
              margin-top: 10px;
              font-size: 18px;
              color: #888;
              .main {
                color: @mainColor;
              }
            }
          }
        }
      }
    }
    .img-wrap {
      margin-left: 100px;
      img {
        width: 100%;
      }
    }
    &.form-image {
      .img-wrap {
        margin-left: 0;
      }
      .content_l {
        margin-left: 100px;
      }
    }
  }
  .sub-title {
    margin-top: 30px;
    font-size: 22px;
    color: #535960;
    text-align: center;
  }
  .scene {
    .scene-content {
      display: flex;
      margin-top: 40px;
      img {
        width: 100%;
      }
    }
  }

  .more-scene {
    margin-top: 88px;
    .title {
      font-size: 36px;
      color: #535960;
      font-weight: 500;
      text-align: center;
    }
    .more-scene-content {
      display: flex;
      justify-content: space-between;
      margin-top: 60px;
      li {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 232px;
        background: #fff;
        border-radius: 4px;
        &:not(:first-child) {
          margin-left: 10px;
        }
        .text-title {
          margin-top: 40px;
          font-size: 22px;
          color: #555;
          font-weight: 500;
        }
        .text {
          font-size: 18px;
          color: #fff;
          display: none;
          &:last-child {
            margin-top: 20px;
          }
        }
        .scene-img {
          width: 40px;
        }
        &:hover {
          box-shadow: @boxShadow;
          background: @mainColor;
          img,
          .text-title {
            display: none;
          }
          .text {
            display: block;
          }
        }
      }
    }
  }
  .doc-content {
    display: grid;
    grid-template-columns: repeat(5, 20%);
    margin-top: 53px;

    a {
      color: #bfcddd;
      font-size: 18px;
      text-align: center;
      line-height: 32px;
      font-weight: 500;

      &:not(:last-child) {
        border-right: 1px solid #fff;
      }

      &:hover {
        color: #fff;
      }
    }
  }
  .price {
    margin-top: 60px;
  }
}
</style>
